<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 100px;
  text-align: left;
}
</style>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <!--          <a-col :md="4" :sm="16">-->
          <!--            <a-form-item label="仓库编号">-->
          <!--              <a-input v-model="queryParam.code" placeholder=""/>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :md="6" :sm="24">
            <a-form-item label="仓库名称">
              <a-input v-model="queryParam.nameLike" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="-1">全部</a-select-option>
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 6) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_warehouse_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content', y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="address" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
      </span>
      <span slot="businessHours" slot-scope="text">
        <ellipsis :length="15" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:but_warehouse_edit>编辑&ensp;|&ensp;</a>
          <a @click="handleDel(record)" v-action:but_warehouse_del>删除&ensp;|&ensp;</a>
          <a @click="updateStatus(record)" v-action:but_warehouse_del>{{ record.status == 1 ? '禁用' : '启用' }}</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addWarehouse"
      title="新增/编辑"
      :width="500"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addWarehouseHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="仓库名称"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" name="name" />
          </a-form-item>

          <a-form-item
            label="仓库地址"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-cascader
              :options="addressoptions"
              placeholder="请选择"
              v-decorator="['addressxz', { rules: [{ required: true, message: '请选择省市区' }] }]"
            />
          </a-form-item>

          <a-form-item
            label="仓库地址"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <!--            <a-input-->
            <!--              v-decorator="[-->
            <!--            'address',-->
            <!--            {rules: [{ required: true, message: '请输入地址' }]}-->
            <!--          ]"-->
            <!--              name="address"/>-->

            <a-textarea
              v-decorator="['address', { rules: [{ required: true, message: '请输入地址' }] }]"
              name="address"
            ></a-textarea>
          </a-form-item>

          <a-form-item
            label="营业时间"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['businessHours', { rules: [{ required: true, message: '请输入营业时间' }] }]"
              placeholder="8:00-9:00"
              name="businessHours"
            />
          </a-form-item>

          <a-form-item
            label="联系人"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['contacts', { rules: [{ required: true, message: '请输入联系人' }] }]"
              name="contacts"
            />
          </a-form-item>

          <a-form-item
            label="联系方式"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['contactTel', { rules: [{ required: true,pattern: /^1[34578]\d{9}$/, message: '请正确输入联系方式' }] }]"
              name="contactTel"
            />
          </a-form-item>

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 8px" @click="addWarehouseCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { findPageWarehouse, updateWarehouse, saveWarehouse, deleteWarehouse, getGeoAddress } from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '名称',
          width: '150px',
          key: 'name',
          dataIndex: 'name',
          align: 'center',
          fixed: 'left',
        },
        {
          title: '地址',
          width: '150px',
          key: 'address',
          dataIndex: 'address',
          align: 'center',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '联系人',
          width: '150px',
          key: 'contacts',
          dataIndex: 'contacts',
          align: 'center',
        },
        {
          title: '联系方式',
          width: '150px',
          key: 'contactTel',
          dataIndex: 'contactTel',
          align: 'center',
        },
        {
          title: '营业时间',
          width: '150px',
          key: 'businessHours',
          dataIndex: 'businessHours',
          align: 'center',
          scopedSlots: { customRender: 'businessHours' },
        },
        {
          title: '省',
          width: '150px',
          key: 'province',
          dataIndex: 'province',
          align: 'center',
        },
        {
          title: '市',
          width: '150px',
          key: 'city',
          dataIndex: 'city',
          align: 'center',
        },
        {
          title: '县',
          width: '150px',
          key: 'district',
          dataIndex: 'district',
          align: 'center',
        },
        {
          title: '坐标',
          width: '150px',
          key: 'location',
          dataIndex: 'orgName',
          align: 'center',
        },
        {
          title: '状态',
          width: '150px',
          key: 'status',
          dataIndex: 'status',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '240px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageWarehouse(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      addressoptions: [], //省市区选择数据
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      this.visible_add = true
      this.tabObj = record
      this.addFlag = false
      let addressxz = []
      if (record.administrativeCode != null) {
        let administrativeCodesp = record.administrativeCode.split(',')
        addressxz[0] = administrativeCodesp[0] - 0
        if (administrativeCodesp.length > 1) {
          addressxz[1] = administrativeCodesp[1] - 0
        }
        if (administrativeCodesp.length > 2) {
          addressxz[1] = administrativeCodesp[1] - 0
          addressxz[2] = administrativeCodesp[2] - 0
        }
      }

      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          address: record.address,
          contacts: record.contacts,
          contactTel: record.contactTel,
          businessHours: record.businessHours,
          addressxz: addressxz,
        })
      })
      this.initGeoAddress()
    },
    handleDel(record) {
      deleteWarehouse(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateWarehouse({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    handleOk() {},

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addWarehouseCancel() {
      this.visible_add = false
    },
    handleAdd() {
      this.visible_add = true
      this.tabObj = {}
      this.addFlag = true
      this.initGeoAddress()
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if(values.addressxz==null||values.addressxz.length<3){
              this.$message.error("地址不能为空")
              return false
          }
          if (that.addFlag) {
            //新增角色
            saveWarehouse(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            //修改角色
            values.id = this.tabObj.id
            updateWarehouse(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    addWarehouseHandleCancel() {
      this.visible_add = false
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    initGeoAddress() {
      let that = this
      getGeoAddress({ showDistrict: 1, showCity: 1 }).then((res) => {
        //提交成功
        that.addressoptions = res.result
      })
    },
    mobilephoneValidate (rule, value, callback) {
      // 主要就是添加一个对undefined和空串的判断
      if (typeof (value) === 'undefined' || value === '') {
        callback()
      } else {
        if (!this.testMobilephone(value)) {
          callback(new Error('请输入正确手机格式'))
        }
        callback()
      }
    },
    testMobilephone: function (str) {
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(str)) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.5%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-btn-primary{
    margin-right: 3px;
  }
}
</style>